import styled from '@emotion/styled'
import { Checkbox, Divider } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { IconBin } from '../../assets/images/images-scg'
import Button from '../../components/common/Button'
import Image from '../../components/common/Image'
import { InputLabel } from '../../components/common/Input'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import UploadFile from '../../components/common/UploadFile'
import config from '../../config'
import color from '../../constant/colors'
import * as paths from '../../constant/path'
import { useCancelEClaim } from '../../services/e-claim/e-claim-query'
import { EClaimHistory } from '../../services/e-claim/e-claim-types'
import { useVisibility } from '../../utils/custom-hook'
import { numberWithCommas } from '../../utils/helper'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 8px;
`

const CheckBoxStyle = styled(Checkbox)`
  font-size: 18px;
  color: #E87722;
  font-family: NotoSansThai-Bold;
`

const EClaimInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const EClaimStatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const EClaimStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const EClaimStatusLayout = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`
const GroupButtonContainer = styled.div`
  display: flex;
  column-gap: 16px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 30%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`
const TextModal = styled(Text)`
  text-align: center;
`

const ErrorIcon = styled(Image)`
  width: 72px;
  height: 72px;
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;

  ${mobile} {
    flex-direction: column;
  }
`

type EClaimInfoProps = {
  id: number
  eClaimItem?: EClaimHistory
  // onCreateNewEClaim: () => void
}
const EClaimInfo = (prop: EClaimInfoProps) => {
  const { id, eClaimItem } = prop
  const history = useHistory()
  const [visible, modalAction] = useVisibility()
  const { t, i18n } = useTranslation()
  const { mutate: cancelEClaim } = useCancelEClaim()

  const date = useMemo(() => {
    return dayjs(eClaimItem?.createdDate).format('DD/MM/YYYY')
  }, [eClaimItem?.createdDate])

  const statusText = useMemo(() => {
    let statusText = ''
    switch (eClaimItem?.claimStatus) {
      case 'AP':
        statusText = 'Approved'
        break;
      case 'RE':
        statusText = 'Rejected'
        break;
      case 'PD':
        statusText = 'Pending'
        break;
      case 'CC':
        statusText = 'Cancelled'
        break;
      default: statusText = 'Pending'
        break;
    }
    return statusText
  }, [eClaimItem?.claimStatus])

  const statusCode = useMemo(() => {
    let statusCode = ''
    switch (eClaimItem?.claimStatus) {
      case 'AP':
        statusCode = '#03824F'
        break;
      case 'RE':
        statusCode = '#B30909'
        break;
      case 'PD':
        statusCode = '#FED141'
        break;
      case 'CC':
        statusCode = '#DBDFE1'
        break;
      case '':
        statusCode = '#03824F'
        break;
      default: statusCode = '#03824F'
        break;
    }
    return statusCode
  }, [eClaimItem?.claimStatus])

  // const statusColor = useMemo(() => {
  //   return eClaimItem?.accept === null ? color.PENDING : eClaimItem?.accept ? color.APPROVE : color.REJECT
  // }, [eClaimItem?.accept])
  // const backgroundColor = useMemo(() => {
  //   return eClaimItem?.accept === null ? color.PENDING_LIGHT : eClaimItem?.accept ? color.APPROVE_LIGHT : color.REJECT_LIGHT
  // }, [eClaimItem?.accept])

  // const PaymentDateGetPaid = useMemo(() => {
  //   console.log(dayjs(eClaimItem?.updatedDate).date())
  //   if (dayjs(eClaimItem?.updatedDate).date() >= 25) {
  //     return dayjs(eClaimItem?.updatedDate).set('date', 25).add(2, 'month').format('DD MMM YYYY')
  //   } else {
  //     return dayjs(eClaimItem?.updatedDate).set('date', 25).add(1, 'month').format('DD MMM YYYY')
  //   }
  // }, [eClaimItem?.updatedDate])

  const onBackPath = useCallback(() => {
    history.push(paths.eClaimHistory())
  }, [history])

  const onCancleButtonPress = useCallback(() => {
    cancelEClaim(id, {
      onSuccess: () => {
        onBackPath()
      },
      onError: (err) => {
        alert(err)
      }
    })
  }, [id, cancelEClaim])

  return (
    <Layout>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        hideFooter
        hideCloseIcon
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          rowGap: 24
        }}>
          <ErrorIcon src={IconBin} />
          <TextModal size={24} type="Bold">
            {t('eClaim.confirmCancel')}
          </TextModal>

          <GroupButtonContainer>
            <Button
              background={color.WHITE}
              fontColor={color.PRIMARY}
              onClick={() => modalAction.hide()}
            >
              {t('eClaim.cancelClaimButton')}
            </Button>
            <Button background={color.PRIMARY} fontColor={color.WHITE}
              onClick={() => onCancleButtonPress()}
            >
              {t('eClaim.confirmButton')}
            </Button>
          </GroupButtonContainer>
        </div>
      </Modal>
      <Text size={24} type="Bold">{t('app.description')}</Text>
      <EClaimInfoContainer>
        <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('app.claimName')}</Text>
        <Text size={18} type="SemiBold">{i18n.language === 'en' ? eClaimItem?.claim.nameEn : eClaimItem?.claim.name} {eClaimItem?.subClaim?.name !== undefined && `(${i18n.language === 'en' ? `${eClaimItem?.subClaim?.nameEn}` : `${eClaimItem?.subClaim?.name}`})`}</Text>
      </EClaimInfoContainer>

      {
        eClaimItem?.userRemark !== null && (
          <EClaimInfoContainer>
            <Text size={16} color={color.FONT_LIGHT_GRAY}>{eClaimItem?.claim?.id === 20 ? `${t('eClaim.field.claimFor2')}` : `${t('eClaim.field.claimFor')}`}</Text>
            <Text size={18} type="SemiBold">{eClaimItem?.userRemark}</Text>
          </EClaimInfoContainer>
        )
      }

      {eClaimItem?.claim?.claimType?.id === 3 && (
        <>
          <EClaimInfoContainer>
            <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('eClaim.field.damageInfo')}</Text>
            <Text size={18} type="SemiBold">{eClaimItem?.claimDescription}</Text>
          </EClaimInfoContainer>

          <EClaimInfoContainer>
            <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('eClaim.field.completeClaim')}</Text>
            <CheckBoxStyle
              className="check-box"
              disabled={true}
              checked={eClaimItem?.claimsSelected.findIndex((value: any) => value?.claim?.id === 23) >= 0}
              style={{ marginLeft: 8 }}
            >
              {t("eClaim.claimTopics.first")}
            </CheckBoxStyle>
            <CheckBoxStyle
              className="check-box"
              disabled={true}
              checked={eClaimItem?.claimsSelected.findIndex((value: any) => value?.claim?.id === 24) >= 0}
            >
              {t("eClaim.claimTopics.second")}
            </CheckBoxStyle>
            <CheckBoxStyle
              className="check-box"
              disabled={true}
              checked={eClaimItem?.claimsSelected.findIndex((value: any) => value?.claim?.id === 25) >= 0}
            >
              {t("eClaim.claimTopics.third")}
            </CheckBoxStyle>
            <CheckBoxStyle
              className="check-box"
              disabled={true}
              checked={eClaimItem?.claimsSelected.findIndex((value: any) => value?.claim?.id === 26) >= 0}
            >
              {t("eClaim.claimTopics.fourth")}
            </CheckBoxStyle>
          </EClaimInfoContainer>
        </>
      )}

      <EClaimInfoContainer>
        <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('eClaim.field.docNum')}</Text>
        <Text size={18} type="SemiBold">{eClaimItem?.claimNumber}</Text>
      </EClaimInfoContainer>
      <EClaimInfoContainer>
        <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('eClaim.field.dateClaim')}</Text>
        <Text size={18} type="SemiBold">{date}</Text>
      </EClaimInfoContainer>
      <EClaimInfoContainer>
        <EClaimStatusContainer>
          <EClaimStatusWrapper>
            <Text size={16} color={color.FONT_LIGHT_GRAY}>{t('eClaim.field.status')}</Text>
            <EClaimStatusLayout backgroundColor={statusCode}>
              <Text size={18} type="SemiBold" color={'#FFF'}>{statusText}</Text>
            </EClaimStatusLayout>
            {eClaimItem?.claimStatus === 'RE' && `- ${(eClaimItem?.claimRemark)}`}
            {eClaimItem?.claimStatus === 'RE' &&
              (
                <>
                  {
                    eClaimItem?.reasons?.map((reason: any) => {
                      return <Text size={18} type="SemiBold">- {i18n.language === 'en' ? reason.nameEn : reason.nameTh}</Text>
                    })
                  }
                </>
              )}
          </EClaimStatusWrapper>

        </EClaimStatusContainer>
      </EClaimInfoContainer>
      <Divider />
      {eClaimItem?.claimStatus === 'PD' && (
        <GroupButtonContainer>
          <Button
            background={color.WHITE}
            fontColor={color.PRIMARY}
            onClick={() => modalAction.show()}
          >
            {t('eClaim.cancelButton')}
          </Button>
        </GroupButtonContainer>
      )}

      {/* {(eClaimItem?.claimStatus === 'RE' || eClaimItem?.claimStatus === 'CC') && (
        <GroupButtonContainer>
          <Button
            background={color.PRIMARY}
            fontColor={color.WHITE}
            onClick={() => modalAction.show()}>
              {t('eClaim.newRequestButton')}
          </Button>
        </GroupButtonContainer>
      )} */}

      <InputContainer>
        <FlexCol>
          <InputLabel>{t('eClaim.field.fullName')}</InputLabel>
          <Text type="Bold">{eClaimItem?.relative !== null ? `${i18n.language === 'en' ? `${eClaimItem?.relative?.firstnameEn} ${eClaimItem?.relative?.lastnameEn}` : `${eClaimItem?.relative?.firstnameTh} ${eClaimItem?.relative?.lastnameTh}`}` : i18n.language === 'en' ? `${eClaimItem?.user?.firstName} ${eClaimItem?.user?.lastName}` : `${eClaimItem?.user?.firstNameTH} ${eClaimItem?.user?.lastNameTH}`}</Text>
        </FlexCol>
      </InputContainer>

      <InputContainer>
        <FlexCol>
          <InputLabel>{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 17, 16, 19].includes(eClaimItem?.id) ? `${t('eClaim.field.receiptDate')}` : `${t('eClaim.field.claimDate')}`}</InputLabel>
          <Text type="Bold">{moment(eClaimItem?.claimDate).format('DD/MM/YYYY')}</Text>
        </FlexCol>
      </InputContainer>

      <InputContainer>
        <FlexCol>
          <InputLabel>{t('eClaim.field.amountReceipt')}</InputLabel>
          <Text type="Bold">{numberWithCommas(eClaimItem?.claimAmount || 0)}</Text>
        </FlexCol>
      </InputContainer>

      {
        (eClaimItem?.claimStatus === 'AP') && (
          <InputContainer>
            <FlexCol>
              <InputLabel>{t('eClaim.field.actualAmount')}</InputLabel>
              <Text type="Bold">{numberWithCommas(eClaimItem?.claimApproveAmount || 0)}</Text>
            </FlexCol>
          </InputContainer>
        )
      }


      <Text size={24} type="Bold">
        {t('app.attachment')}
      </Text>

      {
        eClaimItem?.claimDocumentTypes?.map((data: any, index: any) =>
          <FileUploadContainer>
            <UploadFile
              icon={'receiptIcon'}
              title={i18n.language === 'en' ? data.nameEn : data.name}
              desc={t('app.attachmentSize')}
              value={`${config.apiHost}/api/user-claim/files${data?.userClaimFiles[0]?.filePath}`}
              isPreview
              // validate={validateImage}
              maximumFileSize={3}
              disabled
            />
          </FileUploadContainer>

        )
      }
    </Layout>
  )
}

export default EClaimInfo

